.business-list-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-height: 85%;
  overflow: hidden;
}

.no-search {
  margin-top: 40px;
  font-style: italic;
  font-weight: 600;
  font-size: 1.5em;
  color: #86898A;
}

.search-query {
  margin: 0 30px;
  display: none;
  font-style: italic;
  font-size: 1em;
  text-align: left;
  color: #54585A;
}

.show-query {
  display: block;
  padding: 10px 0 20px 10px;
  border-bottom: 1px solid #C7C7C7;
}


.business-list {
  margin: 0 30px;
  overflow: scroll;
  transition: height 3s ease-in;
  .business-option {
    margin: 10px;
    padding: 5px;
    height: 15%;
    border: #ba0c2f 1px solid;
  }
}

.search {
  padding: 10px 20px 30px 30px;
  border-bottom: 2px solid #54585A;
  font-size:1.4rem ;
  h1 {
    margin: 0 0 5px 5px;
    font-size: 1em;
    text-align: left;
    color: #54585A;
  }

  .search-bar {
    position: relative;
    padding: 10px 15px;
    display: flex;
    border: 1px #D9D9D9 solid;
    border-radius: 30px;
    background: white;

    input {
      border: none;
      outline: none;
      width: calc(100% - 15px);
      font-size: 1em;
      color: #54585A;
      background: none;
    }

    img {
      width: 15px;
    }
  }

  .suggestions {
    z-index: 3;
    display: none;
    list-style: none;
    padding: 0 0 15px 0;
    position: absolute;
    top: calc(50% - 1px);
    height: fit-content;
    width: 100%;
    left: -1px;
    border: 1px #D9D9D9 solid;
    border-radius: 0 0 30px 30px;
    background-color: white;
    color: #B2B5B7;
    text-align: left;
    font-size: .8em;
    font-style: italic;
  }

  .suggestions li{
    cursor: pointer;
    padding: 2.5px 15px 2.5px 15px;
    transition: background-color .1s ease;
  }

  .suggestions li:first-child{
    padding-top: 7.5px;
  }
  .suggestions li:last-child{
    border-radius: 0 0 10px 10px;
  }

  .suggestions li:hover{
    background-color: #f2f2f2;
    color: black;
  }

  .show-suggestions {
    border-radius: 20px 20px 0 0;
    padding-bottom: 20px;

    .suggestions {
      display: block;
    }
  }
}