.search-result:hover{
    background-color: #d9d9d9;
}

.selected{
    background-color: #d9d9d9;
    & > .text > h2{
        color: #ba0c2f;
    }
}

.search-result{
    cursor: pointer;
    padding: 10px 10px;
    border-bottom: 1px solid #C7C7C7;
    transition: background-color .1s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2{
        margin: 0 0 5px 0;
        padding-right: 1em;
        font-size: 1.2em;
        text-align: left;
    }

    
    .categories{
        display: flex;
        width: 100%;

        img{
            display: none;
            margin-right: 5px;
            width: 20px;
            height: 20px;
        }
    
        .show{
            display: inline;
        }
    }
}







 
