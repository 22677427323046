.search-page{
  --red:#ba0c2f;
  --grey:#EFEFF1;
  --radius:30px;

  display: flex;
  margin: 30px auto;
  width: 90vw;
  max-height: 90vh;
  min-height: 600px;
  background-color: white;
  border-radius: var(--radius);
  overflow: scroll;
  .headers{
    font-size: 2rem;
    color: white;
    padding: 20px 0 10px 0;
    box-sizing: border-box;
    text-align: left;
  }
  .headers span{
    margin-left: 30px;
  }
  .list{
    width: 32%;
    background: var(--grey);
    box-sizing: border-box;
    border-radius:  var(--radius) 0 0 var(--radius);
    .list-header{
      background-color: var(--red);
      border-radius:  var(--radius) 0 0 0;
    }
  }
  .details {
    width: 72%;
    min-height: 100%;
    max-height: 100%;
    border-bottom: var(--grey) 2px solid;
    border-right: var(--grey) 2px solid;
    border-radius:  0 var(--radius) var(--radius) 0  ;
    box-sizing: border-box;
    overflow: hidden;
    .details-header{
      background: #54585A;
      outline: #54585A;
      border-radius: 0 var(--radius) 0 0;
    }
    .business-details-results{
      height: 85%;
      padding-bottom: 10px;
      overflow: hidden;
      .no-business{
        color: #ABABAB;
        font-family: 'Open Sans', serif;
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30pt;
      }
    }
  }
}