@mixin format-text {
  color: #54585A;
  text-align: left;
  font-family: 'Open Sans', serif;
}

.business-details-container {
  --grey-font: #54585A;
  --border-color: #C7C7C7;
  --red-font: #ba0c2f;

  margin: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  overflow: hidden;
  max-height: 100%;
  padding: 3em;
  .company-name-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    @include format-text;

    & .company-name {
      color: var(--grey-font);
      font: 600 40px 'Open Sans';
      padding: 0;
      margin: 0;
    }

    & .dba {
      font: 400 normal 24px 'Open Sans';
      color: var(--grey-font);
      text-align: left;
    }

    .icons {
      display: flex;
      justify-content: space-evenly;
      width: 20%;

      & img {
        margin: auto;
      }
    }
  }

  .information-container {
    display: flex;
    width: 100%;
    max-height: 90%;
    min-height: 90%;
    overflow: hidden;
    .business-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      max-height: 100%;
      min-height: 100%;
      margin: 0 10px;
      overflow: hidden;

      .business-categories {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        overflow: scroll;
        height: 100%;
        width: 100%;
        .category {
          padding: 10px 0;
          font-size: 1.125rem;
          @include format-text;
          &:first-child {
            border-top: var(--border-color) 1px solid;
          }
          &:last-child{
            margin-bottom: 10px;
          }
          p {
            font-size: 0.9rem;
            padding: 0 ;
            margin: 0;
            color: var(--red-font);
          }
        }
      }
    }

    .contact-container {
      width: 50%;
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      .contact-information {
        box-sizing: border-box;
        background-color: #EFEFF1;
        padding: 1.3em;
        text-align: left;
        overflow: scroll;
      }
      .ethnic-groups {
        overflow: scroll;
        width: 100%;
        font-weight: bold;
        @include format-text;
        color: var(--red-font);

        h1 {
          color: var(--grey-font);
        }

        .group-names {
          padding: 10px 0 10px 0;
        }
      }
    }
  }

}

h2 {
  font-size: 1.5rem;
  @include format-text;
}

p{
  font-weight: normal;
  font-size: 1.1rem;
  margin: 5px 0 10px 0 ;
  @include format-text;
}
 label{
  font-size: 1.25rem;
   margin: 0 0 10px 0;
  @include format-text;
}

.border-top {
  border-top: var(--border-color) 1px solid;
}

.border-bottom {
  margin: 0;
  padding: 0;
  border-bottom: var(--border-color) 1px solid;
}

.show {
 display: flex;
}

.hide {
  display: none;
}
.icon-container{
  padding: 0 5px;
}
.no-business {
  height: 400px;
  width: 400px;
  margin: auto;
}
a:-webkit-any-link {
  cursor: pointer;
  margin-bottom: 5px;
  text-decoration: none;
  @include format-text;
  &:hover{
    text-decoration: underline;
    color: #ba0c2f;
  }
}